<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li>Fahrzeug hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicles" class="button button-grey button-small">Alle Fahrzeuge ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <form @submit="create_vehicle" class="form" method="post">

              <div class="form-wrap">
                <label for="license_plate">Kennzeichen</label>
                <input v-model="vehicle.license_plate" class="form-input" type="text" name="license_plate" id="license_plate" placeholder="Kennzeichen">
              </div>

              <div class="form-wrap">
                <label for="mileage">Kilometerstand</label>
                <input v-model="vehicle.mileage" class="form-input" type="text" name="mileage" id="mileage" placeholder="Kilometerstand">
              </div>

              <div class="form-wrap">
                <label for="vehicle_model">Modell</label>
                <input v-model="vehicle.vehicle_model" class="form-input" type="text" name="vehicle_model" id="vehicle_model">
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Marke</label>
                <select v-model="vehicle.make_id" class="select-wrap">
                  <option v-for="make in vehicle_makes" v-bind:value="make.id" v-bind:key="make.id">
                    {{ make.name }}
                  </option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Gruppe</label>
                <select v-model="vehicle.group_id" class="select-wrap">
                  <option v-for="group in vehicle_groups" v-bind:value="group.id" v-bind:key="group.id">
                    {{ group.name }}
                  </option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Kategorie</label>
                <select v-model="vehicle.category_id" class="select-wrap">
                  <option v-for="category in vehicle_categories" v-bind:value="category.id" v-bind:key="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Standort</label>
                <select v-model="vehicle.location_id" class="select-wrap">
                  <option v-for="location in locations" v-bind:value="location.id" v-bind:key="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>

              <p>
                <input type="submit" value="Speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      vehicle_makes: [],
      vehicle_groups: [],
      vehicle_categories: [],
      locations: [],
      vehicle: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_makes', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_makes = response.data.vehicle_makes;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_groups', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_groups = response.data.vehicle_groups;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_categories = response.data.vehicle_categories;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

    },
    create_vehicle: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles', this.vehicle, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
        this.$router.push('/admin/vehicles');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
